import React, { useEffect, useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModerationApp from "./ModerationApp";
// import RuleStudioApp from "./RuleStudioApp";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {
    Toolbar,
    Button
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{padding: 0}} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const App = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [darkState, setDarkState] = useState(false);
  const palletType = darkState ? "dark" : "light";
  const darkTheme = createMuiTheme({
    palette: {
      type: palletType,
    }
  });
  const handleThemeChange = () => {
    setDarkState(!darkState);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar position="static">
          <Box display="flex">
              <Tabs value={value} onChange={handleChange}>
                <Tab label="KaiAds Moderation UI" {...a11yProps(0)} />
                {/* <Tab label="Moderator Rule Studio" {...a11yProps(1)} /> */}
              </Tabs>
              <Box flexGrow={1} />
              <Button onClick={handleThemeChange} style={{color: "#FFFFFF"}}>
                Theme: {palletType}
                <Switch checked={darkState} onChange={handleThemeChange} color="default"/>
              </Button>
          </Box>
        </AppBar>
        <TabPanel value={value} index={0}>
          <ModerationApp />
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          <RuleStudioApp />
        </TabPanel> */}
      </div>
    </ThemeProvider>
  );
};

export default App;
