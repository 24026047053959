export const StatusOption = {
  False: "Blacklisted",
  True: "Whitelisted",
};

export const StatusOptionToBool = (b) => {
  return b === StatusOption.False ? "false" : b === StatusOption.True ? "true" : null;
};

export const StatusOptionToOppositeBool = (b) => {
  return b === StatusOption.False ? "true" : b === StatusOption.True ? "false" : null;
};
