import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Grid, Button, ButtonGroup, Typography } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import TranslateIcon from '@material-ui/icons/Translate';
import Tooltip from '@material-ui/core/Tooltip'
import moment from "moment";
import { useDebouncedCallback } from "use-debounce";
import { DateFormat } from "../constants/datetime";
import { SortDirection } from "../constants/sorting";
import { UPDATE_PAGE_NUMBER, UPDATE_PAGE_NUMBER_AND_SIZE } from "../actions/pagination";
import { UPDATE_SORT_DIRECTION } from "../actions/sorting";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// Common settings
import { banFilters } from "./settings/ModerationFlags";

const tableTheme = createMuiTheme({
  palette: {
    type: "light",
  },
});

export const cells = [
  { key: "fingerprint", sortable: true, label: "Fingerprint" },
  { key: "partner", sortable: true, label: "Demand Partner" },
  { key: "ad_source", sortable: true, label: "Source" },
  { key: "ad_image_s3_url", sortable: true, label: "Image" },
  { key: "ad_text", sortable: true, label: "Text" },
  { key: "bid_price", sortable: true, label: "Avg. Bid Price" },
  { key: "successful_bids", sortable: true, label: "# Bids" },
  { key: "impressions", sortable: true, label: "Imps" },
  { key: "clicks", sortable: true, label: "Clicks" },
  { key: "banned_filters", sortable: false, label: banFilters.map(o => o.label).join('/') },
  { key: "ad_image_score", sortable: true, label: "Image Score" },
  { key: "ad_text_score", sortable: true, label: "Text Score" },
  { key: "last_seen", sortable: true, label: "Last Seen" },
];

const ModerationTableHead = (props) => {
  const { sorting, onSortingUpdate, data, onSettingUpdate } = props;

  const debouncedDirectionChange = useDebouncedCallback((key) => onSortingUpdate({ type: UPDATE_SORT_DIRECTION, key }), 25);

  let allCheckboxes = 1;
  let tickedCheckboxes = 0;
  if (data.length > 0) {
    allCheckboxes = data.length;
    tickedCheckboxes = data.map(setting => setting.is_reviewed ? 1 : 0).reduce((a, b) => a + b);
  }

  const isAllReviewed = allCheckboxes > 0 && tickedCheckboxes === allCheckboxes;

  const onSelectAllClick = () => {
    data.map((setting, i) => {
      setting.is_reviewed = !isAllReviewed;
      onSettingUpdate(i, "is_reviewed", setting.is_reviewed, setting, true);
    })
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          Reviewed
          <Checkbox
            indeterminate={tickedCheckboxes > 0 && tickedCheckboxes < allCheckboxes}
            checked={isAllReviewed}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {cells.map((cell) => (
          <TableCell key={cell.key} align="center" sortDirection={sorting[cell.key]}>
            <TableSortLabel
              active={cell.sortable ? !!sorting[cell.key] : false}
              direction={sorting[cell.key] ? sorting[cell.key] : SortDirection.ASC}
              hideSortIcon={!cell.sortable}
              onClick={cell.sortable ? () => debouncedDirectionChange.callback(cell.key) : undefined}
            >
              {cell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ModerationTableHead.propTypes = {
  sorting: PropTypes.object.isRequired,
  onSortingUpdate: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
}));

const ModerationTable = (props) => {
  const classes = useStyles();

  const { data, sorting, onSortingUpdate, pagination, onPaginationUpdate, onSettingUpdate } = props;

  const { settings, total_elements } = data;
  const { page, size } = pagination;

  const [applyAllToNonReviewedItemsOnly, setApplyAllToNonReviewedItemsOnly] = useState(true);
  const handleApplyAllToNonReviewedItemsOnly = (event) => {
    setApplyAllToNonReviewedItemsOnly(event.target.checked);
  };

  // Datatable styles
  const adTextColumnStyle = {
    minWidth: "350px",
    maxWidth: "350px",
    backgroundColor: "#e0f7fa"
  };
  const checkBoxColumnStyle = {
    minWidth: "200px",
    backgroundColor: "#eceff1"
  };
  const VeryCompactTableCss = withStyles({
    // @global is handled by jss-plugin-global.
    '@global': {
      // You should target [class*="MuiButton-root"] instead if you nest themes.
      '.MuiTableCell-sizeSmall': {
        padding: 1,
      },
    },
  })(() => null);
  const AdScoreStyle = (score) => {
    if (score >= 0.8) {
      return {
        backgroundColor: "#ef9a9a"
      };
    } else if (score >= 0.5) {
      return {
        backgroundColor: "#ffe082"
      };
    } else if (score >= 0.1) {
      return {
        backgroundColor: "#a2cf6e"
      };
    } else {
      return {
      };
    }
  }

  // Review checkboxes
  const isReviewItemSelected = (setting, i) => {
    setting.is_reviewed = !setting.is_reviewed;
    onSettingUpdate(i, "is_reviewed", settings[i].is_reviewed, setting, true);
  };

  // Allow/Deny All
  const onApproveAll = (is_allowed, key) => {
    if (key == 'banned') {
      // Flip flag for global
      is_allowed = !is_allowed;
    }

    settings.map((setting, i) => {
      console.log(i, key, setting[key], is_allowed, applyAllToNonReviewedItemsOnly)
      if (!applyAllToNonReviewedItemsOnly || (applyAllToNonReviewedItemsOnly && setting['is_reviewed'] != true)) {
        setting[key] = is_allowed;
        onSettingUpdate(i, key, setting[key], setting, false);
      } 
    })
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.grid} spacing={3}>
        <Grid item xs={12} sm={6} md={5}>
          <div style={{ display: "flex", maxHeight: 35 }}>
            Allow All:
            <ButtonGroup fullWidth variant="contained" color="primary" aria-label="contained primary button group">
              {banFilters.map((cell, j) => (
                /* checked: Allow Global === banned -> false */
                // <Checkbox checked={j === 0 ? !setting[cell.key] : setting[cell.key]} color="primary" onClick={() => onSettingUpdate(i, cell.key, !setting[cell.key], setting)} />
                <Button key={`allow-all-btn-${j}`} style={{ backgroundColor: green[600], borderColor: green[800] }} onClick={() => onApproveAll(true, cell.key)}>{cell.label}</Button>
              ))}
            </ButtonGroup>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <div style={{ display: "flex", maxHeight: 35 }}>
            Deny All:
            <ButtonGroup fullWidth variant="contained" color="primary" aria-label="contained primary button group">
              {banFilters.map((cell, j) => (
                /* checked: Allow Global === banned -> false */
                // <Checkbox checked={j === 0 ? !setting[cell.key] : setting[cell.key]} color="primary" onClick={() => onSettingUpdate(i, cell.key, !setting[cell.key], setting)} />
                <Button key={`deny-all-btn-${j}`} style={{ backgroundColor: red[600], borderColor: red[800] }} onClick={() => onApproveAll(false, cell.key)}>{cell.label}</Button>
              ))}
            </ButtonGroup>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <div style={{ display: "flex", maxHeight: 35 }}>
            <FormControl component="fieldset">
              <FormControlLabel
                control={<Checkbox />}
                label="Apply to non-reviewed items only"
                labelPlacement="end"
                checked={applyAllToNonReviewedItemsOnly} 
                onChange={handleApplyAllToNonReviewedItemsOnly}
                style={{minWidth: 300}}
              />
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <br />
      <ThemeProvider theme={tableTheme}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table size="small">
              <VeryCompactTableCss />
              <ModerationTableHead sorting={sorting} onSortingUpdate={onSortingUpdate} data={settings} onSettingUpdate={onSettingUpdate} />
              <TableBody>
                {settings.map((setting, i) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={`moderation-table-r${i}`}>

                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={setting.is_reviewed}
                        onClick={() => isReviewItemSelected(setting, i)}
                        inputProps={{ 'aria-labelledby': setting.fingerprint }}
                      />
                    </TableCell>

                    <TableCell align="center" style={{ fontSize: 10 }}>{setting.fingerprint}</TableCell>

                    <TableCell component="th" scope="row" align="center" style={{ backgroundColor: "#ede7f6" }}>
                      {setting.partner}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center" style={{ backgroundColor: "#ede7f6" }}>
                      {setting.ad_source}
                    </TableCell>

                    <TableCell align="center" style={{ backgroundColor: "#e0f7fa" }}>
                      <Tooltip title={
                        <img src={setting.ad_image_s3_url}
                          onError={(e) => { e.target.onerror = null; e.target.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAyCAYAAADLLVz8AAACtElEQVRoQ+2bzY4CIQzHGRMvGo0XPej7v48P4ejBi9HoxehsyoZdEhkLpQUc4ejw1R9t/8MgzXa77cbjsZrP52o0GqlacALP51Odz2d1v99V07Zt13WdOh6ParPZqOVyiffwxTWAU9u2mlPTNL8A1+u1ut1u+gEUADmZTL4Y06vpLj6Hw+EfoGliCK9WKwVga1HasVwR6gQIwCC2odH1etXeuFgsvpLj6XTSHKbTqeYAWmGX/X7/6oF2BayDoVL1dSAUoAHU58JDBGiLBHjdu9Ibwq5GkER3u51WniGKDEVEvT3QBjpEkaFGGAngkEQmNseTARqPjJ1ArhzqKxLY/KIBfqLIhIhEMoAwUOkiQxEJDGCQCmOdlbyToYoEZrMIwJJERjpHs+XAvpWSNqBvXC6RwDxQHGAOkeEUCQygWAi7BoYPE5CLpHYyEiJRFEBJkZESiSIBcopMrhxrwCYNYddqUgGkEgnMA5OJCDaRkBBMKRLYvIsBCBMFkYEJQXF9LsshEhjA7CHsmqDLw0I8FDOa83mRAG2RgbNXeO2ZzWbOMwlOGJS+igph2wAjEpfLRf9cAQYsbw3hAFh2VUwkpHcylGkXkwNDRKKkM5nsAD/9RTobQK6dBHUBKOHqapMFoMROIiQFcMGDfpICxEQi1rAcIpMMYEoPkfDwvsUVB5grR3HlWCwqxACmMgAzUHoBRQCmDCEMoHkulUJYAUqLhC+svnoS82MDKLXCsdB8P5dRx4kGKJ1jqIZh7bhyNBkg1wQwQ6WfxzoACWCJIhELmpqCggBKJOFYwznbY2cyUXth6gpxGpiqr5DPZagHxuaIVEZzj+Ob4+tFG4Q85kBvr3rBZTrsngT3ypfan9dVr6GLROziuP7C/OeB9bqrP177NQ7uVzf1wrU/PFPz8XgoOLMGsfkBSge+RJilAVYAAAAASUVORK5CYII=" }}
                          height="auto" width="auto" style={{ minHeight: 100, maxHeight: 250 }} alt="" />
                      } placement='bottom'>
                        <img src={setting.ad_image_s3_url}
                          onError={(e) => { e.target.onerror = null; e.target.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAyCAYAAADLLVz8AAACtElEQVRoQ+2bzY4CIQzHGRMvGo0XPej7v48P4ejBi9HoxehsyoZdEhkLpQUc4ejw1R9t/8MgzXa77cbjsZrP52o0GqlacALP51Odz2d1v99V07Zt13WdOh6ParPZqOVyiffwxTWAU9u2mlPTNL8A1+u1ut1u+gEUADmZTL4Y06vpLj6Hw+EfoGliCK9WKwVga1HasVwR6gQIwCC2odH1etXeuFgsvpLj6XTSHKbTqeYAWmGX/X7/6oF2BayDoVL1dSAUoAHU58JDBGiLBHjdu9Ibwq5GkER3u51WniGKDEVEvT3QBjpEkaFGGAngkEQmNseTARqPjJ1ArhzqKxLY/KIBfqLIhIhEMoAwUOkiQxEJDGCQCmOdlbyToYoEZrMIwJJERjpHs+XAvpWSNqBvXC6RwDxQHGAOkeEUCQygWAi7BoYPE5CLpHYyEiJRFEBJkZESiSIBcopMrhxrwCYNYddqUgGkEgnMA5OJCDaRkBBMKRLYvIsBCBMFkYEJQXF9LsshEhjA7CHsmqDLw0I8FDOa83mRAG2RgbNXeO2ZzWbOMwlOGJS+igph2wAjEpfLRf9cAQYsbw3hAFh2VUwkpHcylGkXkwNDRKKkM5nsAD/9RTobQK6dBHUBKOHqapMFoMROIiQFcMGDfpICxEQi1rAcIpMMYEoPkfDwvsUVB5grR3HlWCwqxACmMgAzUHoBRQCmDCEMoHkulUJYAUqLhC+svnoS82MDKLXCsdB8P5dRx4kGKJ1jqIZh7bhyNBkg1wQwQ6WfxzoACWCJIhELmpqCggBKJOFYwznbY2cyUXth6gpxGpiqr5DPZagHxuaIVEZzj+Ob4+tFG4Q85kBvr3rBZTrsngT3ypfan9dVr6GLROziuP7C/OeB9bqrP177NQ7uVzf1wrU/PFPz8XgoOLMGsfkBSge+RJilAVYAAAAASUVORK5CYII=" }}
                          height="50" width="auto" alt="" />
                      </Tooltip>
                    </TableCell>

                    <TableCell align="center" style={adTextColumnStyle}>
                      <div>{setting.ad_text}</div>
                      {
                        setting.ad_text_language != null && setting.ad_text_language != 'en' &&
                        <>
                          <div><TranslateIcon fontSize="small" style={{ opacity: 0.5 }} />
                            {setting.ad_text_en_translated}</div>
                        </>
                      }
                    </TableCell>

                    <TableCell align="center" style={{ backgroundColor: "#f9fbe7" }}>{setting.bid_price ? setting.bid_price.toFixed(4) : setting.bid_price}</TableCell>
                    <TableCell align="center" style={{ backgroundColor: "#f9fbe7" }}>{setting.successful_bids}</TableCell>
                    <TableCell align="center" style={{ backgroundColor: "#f9fbe7" }}>{setting.impressions}</TableCell>
                    <TableCell align="center" style={{ backgroundColor: "#f9fbe7" }}>{setting.clicks}</TableCell>
                    {/* <TableCell align="center" style={{backgroundColor: "#f9fbe7"}}>{setting.spent.toFixed(4)}</TableCell> */}

                    <TableCell align="center" style={checkBoxColumnStyle}>
                      {banFilters.map((cell, j) => (
                        /* checked: Allow Global === banned -> false */
                        <Checkbox checked={j === 0 ? !setting[cell.key] : setting[cell.key]} color="primary" onClick={() => onSettingUpdate(i, cell.key, !setting[cell.key], setting, false)} />
                      ))}
                    </TableCell>

                    <TableCell align="center" style={AdScoreStyle(setting.ad_image_score)}>
                      <Tooltip title={<div style={{ fontSize: 14 }}>{setting.ad_image_rekognition_raw}</div>} placement='bottom'>
                        <div>{setting.ad_image_score ? setting.ad_image_score.toFixed(4) : setting.ad_image_score}</div>
                      </Tooltip>
                    </TableCell>

                    <TableCell align="center" style={AdScoreStyle(setting.ad_text_score)}>
                      {setting.ad_text_score ? setting.ad_text_score.toFixed(4) : setting.ad_text_score}
                    </TableCell>

                    <TableCell align="center">{moment.unix(setting.last_seen).format(DateFormat)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 50, 100, 200]}
            component="div"
            count={total_elements}
            rowsPerPage={size}
            page={page}
            onChangePage={(event, page) => onPaginationUpdate({ type: UPDATE_PAGE_NUMBER, page: page })}
            onChangeRowsPerPage={(event) =>
              onPaginationUpdate({
                type: UPDATE_PAGE_NUMBER_AND_SIZE,
                page: 0,
                size: parseInt(event.target.value, 10),
              })
            }
          />
        </Paper>
      </ThemeProvider>
    </div>
  );
};

ModerationTable.propTypes = {
  data: PropTypes.object.isRequired,
  sorting: PropTypes.object.isRequired,
  onSortingUpdate: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  onPaginationUpdate: PropTypes.func.isRequired,
  onSettingUpdate: PropTypes.func.isRequired,
};

export default ModerationTable;
