import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}));

const MoederationStats = (props) => {
  const classes = useStyles();

  const { data } = props;

  const watermarkColors = (potential_fill_rate) => {
    if(potential_fill_rate >= 0.9) {
      return "#689f38"
    } else if(potential_fill_rate >= 0.6) {
      return "#0091ea"
    } else {
      return "#dd2c00"
    }
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={6} md={1}>
          Global Ads: {data.current_ads_cnt}
        </Grid>
        <Grid item xs={12} sm={6} md={1}>
          Banned: {data.banned_ads_cnt}
        </Grid>
        <Grid item xs={12} sm={6} md={1}>
          China allowed: {data.allow_china_ads_cnt}
        </Grid>
        <Grid item xs={12} sm={6} md={1}>
          Islamic allowed: {data.allow_islamic_ads_cnt}
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
            <center>|</center>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          Russia Ads (Allowed / All): {data.allow_russia_ads_cnt} / {data.russia_ads_cnt}
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          Egypt Ads (Allowed / All): {data.allow_egypt_ads_cnt} / {data.egypt_ads_cnt}
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          Pakistan Ads (Allowed / All): {data.allow_pakistan_ads_cnt} / {data.pakistan_ads_cnt}
        </Grid>
      </Grid>
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={6} md={3} style={{"color": watermarkColors(data.potential_global_fill_rate)}}>
            Global ~Fill%: {Math.floor(data.potential_global_fill_rate * 100)}%
        </Grid>
        <Grid item xs={12} sm={6} md={3} style={{"color": watermarkColors(data.potential_russia_fill_rate)}}>
            Russia ~Fill%: {Math.floor(data.potential_russia_fill_rate * 100)}%
        </Grid>
        <Grid item xs={12} sm={6} md={3} style={{"color": watermarkColors(data.potential_egpyt_fill_rate)}}>
            Egpyt ~Fill%: {Math.floor(data.potential_egpyt_fill_rate * 100)}%
        </Grid>
        <Grid item xs={12} sm={6} md={3} style={{"color": watermarkColors(data.potential_pakistan_fill_rate)}}>
            Pakistan ~Fill%: {Math.floor(data.potential_pakistan_fill_rate * 100)}%
        </Grid>
      </Grid>
    </>
  );
};

MoederationStats.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MoederationStats;
