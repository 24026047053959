import {
  RESET_PAGE_NUMBER_AND_SIZE,
  UPDATE_PAGE_NUMBER,
  UPDATE_PAGE_NUMBER_AND_SIZE,
  UPDATE_PAGE_SIZE,
} from "../actions/pagination";

export const DefaultState = {
  page: 0,
  size: 10,
};

export const Reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_PAGE_NUMBER:
      return { ...state, page: action.page };
    case UPDATE_PAGE_SIZE:
      return { ...state, size: action.size };
    case UPDATE_PAGE_NUMBER_AND_SIZE:
      return { ...state, page: action.page, size: action.size };
    case RESET_PAGE_NUMBER_AND_SIZE:
      return DefaultState;
    default:
      throw new Error(
          "Exhaustive matching: unknown action during update the state of pagination",
      );
  }
};
