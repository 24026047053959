import { SortDirection } from "../constants/sorting";
import { UPDATE_SORT_DIRECTION } from "../actions/sorting";

export const DefaultState = {
  partner: SortDirection.NATURAL,
  fingerprint: SortDirection.NATURAL,
  image_url: SortDirection.NATURAL,
  impressions: SortDirection.NATURAL,
  clicks: SortDirection.NATURAL,
  banned: SortDirection.NATURAL,
  allow_china: SortDirection.NATURAL,
  allow_russia: SortDirection.NATURAL,
  allow_islamic: SortDirection.NATURAL,
  last_seen: SortDirection.NATURAL,
  order: [],
};

export const Reducer = (state, action) => {
  const direction = state[action.key];
  const order = [...state.order];
  const index = order.indexOf(action.key);

  if (action.type === UPDATE_SORT_DIRECTION) {
    switch (direction) {
      case SortDirection.NATURAL:
        if (index === -1) order.push(action.key);
        return { ...state, [action.key]: SortDirection.DESC, order: order };
      case SortDirection.DESC:
        if (index === -1) order.push(action.key);
        return { ...state, [action.key]: SortDirection.ASC, order: order };
      default:
        if (index > -1) order.splice(index, 1);
        return { ...state, [action.key]: SortDirection.NATURAL, order: order };
    }
  } else {
    throw new Error("Exhaustive matching: unknown action during update the state of sorting");
  }
};
