import React, { useEffect, useReducer, useState } from "react";
import SearchFilters from "./components/SearchFilters";
import MoederationStats from "./components/MoederationStats";
import ModerationTable from "./components/ModerationTable";
import { makeStyles } from "@material-ui/core/styles";
import ModerationAPI from "./api/moderation";
import moment from "moment";
import { DatetimeFormat } from "./constants/datetime";
import { DefaultState as PaginationDefaultState, Reducer as PaginationReducer } from "./reducers/pagination";
import { DefaultState as SortingDefaultState, Reducer as SortingReducer } from "./reducers/sorting";
import { RESET_PAGE_NUMBER_AND_SIZE } from "./actions/pagination";
import { Slide, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),
  },
}));

const ModerationApp = () => {

  // For getting token from URL
  const getQueryParams = (query = null) => (query||window.location.search.replace('?','')).split('&').map(e=>e.split('=').map(decodeURIComponent)).reduce((r,[k,v])=>(r[k]=v,r),{});

  const classes = useStyles();

  const [alert, setAlert] = useState("");
  const handleAlertClose = () => setAlert("");

  const [filters, setFilters] = useState({
    partners: [],
    countries: [],
    min_imps: null,
    max_imps: null,
    min_clicks: null,
    max_clicks: null,
    banned: "",
    allow_china: "",
    allow_russia: "",
    allow_islamic: "",
    after: moment().subtract(7, "day").startOf("day").format(DatetimeFormat),
    before: moment().endOf("day").format(DatetimeFormat),
    token: getQueryParams()['k'],
    auto_moderated_ads: "",
    source_including: "",
    text_including: "",
    not_yet_reviewed_only: false,
  });

  const [data, setData] = useState({
    settings: [],
    total_elements: 0,
    total_pages: 1,
  });

  const [statData, setStatData] = useState({
    "current_ads_cnt": 0,
    "banned_ads_cnt": 0,
    "allow_china_ads_cnt": 0,
    "allow_islamic_ads_cnt": 0,
    "allow_russia_ads_cnt": 0,
    "russia_ads_cnt": 0,
    "allow_egypt_ads_cnt": 0,
    "egypt_ads_cnt": 0,
    "allow_pakistan_ads_cnt": 0,
    "pakistan_ads_cnt": 0,
    "potential_global_fill_rate": 0,
    "potential_russia_fill_rate": 0,
    "potential_egpyt_fill_rate": 0,
    "potential_pakistan_fill_rate": 0,
  });

  const [sorting, dispatchSorting] = useReducer(SortingReducer, SortingDefaultState);
  const [pagination, dispatchPagination] = useReducer(PaginationReducer, PaginationDefaultState);

  const reloadStat = (force) => () => {
    ModerationAPI.getStats(filters)
      .then(({ status, data }) => {
        if (!status && !force) return;
        setStatData(data);
        handleAlertClose();
      })
      .catch((e) => setAlert(e.message));
  };

  const reload = (force) => () => {
    console.log({filters, sorting, pagination, force})
    ModerationAPI.get(filters, sorting, pagination, force)
      .then(({ status, data }) => {
        if (!status && !force) return;
        if (status !== 200) throw new Error(`Network error: ${status}`);
        if (!data) throw new Error("Invalid authentication token");
        setData(data);
        handleAlertClose();
      })
      .catch((e) => setAlert(e.message));
  };

  const btnReload = (force) => () => {
    reload(force)();
    reloadStat(false)();
  };

  useEffect(() => {
    dispatchPagination({ type: RESET_PAGE_NUMBER_AND_SIZE });
  }, [filters]);

  useEffect(() => {
    reload(false)();
    reloadStat(false)();
    console.log({sorting})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, pagination]);

  const handleSettingUpdate = (index, key, value, setting, is_reviewed_manual_override) => {
    const update = { ...setting, [key]: value, "is_reviewed_manual_override": is_reviewed_manual_override };
    ModerationAPI.post(update, filters.token)
      .then((response) => {
        let status = response.status;
        let ret_data = response.data;
        if (status !== 200) throw new Error(`Network error: ${status}`);
        let ret_update = Object.assign(update, ret_data);
        console.log({status, ret_data, ret_update})
        data.settings.splice(index, 1, ret_update);
        setData({ ...data });
        handleAlertClose();
      })
      .catch((e) => setAlert(e.message));
  };

  return (
    <>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={!!alert} autoHideDuration={6000} TransitionComponent={(props) => <Slide {...props} direction="up" timeout={500} />}>
        <Alert onClose={handleAlertClose} severity="error">
          {alert}
        </Alert>
      </Snackbar>
      <div className={classes.container}>
        <SearchFilters filters={filters} onFiltersUpdate={setFilters} onReload={btnReload} />
      </div>
      <MoederationStats 
        data={statData}
      />
      <ModerationTable data={data} sorting={sorting} onSortingUpdate={dispatchSorting} pagination={pagination} onPaginationUpdate={dispatchPagination} onSettingUpdate={handleSettingUpdate
      } />
    </>
  );
};

export default ModerationApp;
