import axios from "axios";
import axiosRetry from "axios-retry";
import moment from "moment";
import queryString from "query-string";
import { StatusOptionToBool, StatusOptionToOppositeBool } from "../constants/status";
import { DatetimeFormat } from "../constants/datetime";

const API = axios.create({
  // baseURL: "https://prodv2-ssp-srv.kaiads.com/",
  // baseURL: "http://ec2-13-229-157-201.ap-southeast-1.compute.amazonaws.com:5000/",
  baseURL: "https://sspdashboard.store.kaiostech.com/",
  // baseURL: "http://127.0.0.1:5000", 
});

axiosRetry(API, {
  retries: 4,
  retryDelay: () => 20,
});

const get = (filters, sorting, pagination, force) => {
  const { token, after, before } = filters;
  const { page } = pagination;

  if (!token || token === "") {
    return force
      ? Promise.reject(new Error("Please provide your authentication token"))
      : Promise.resolve({
        data: {
          settings: [],
          total_elements: 0,
          total_pages: 1,
        },
      });
  }

  const sort = sorting.order
    .map((key) => ({
      key,
      direction: sorting[key],
    }))
    .filter(({ direction }) => direction)
    .map(({ key, direction }) => `${key} ${direction}`);

  let params = queryString.stringify(
    {
      ...{
        ...filters,
        banned: StatusOptionToOppositeBool(filters.banned),
        allow_china: StatusOptionToBool(filters.allow_china),
        allow_russia: StatusOptionToBool(filters.allow_russia),
        allow_islamic: StatusOptionToBool(filters.allow_islamic),
      },
      ...pagination,
      after: moment(after, DatetimeFormat).unix(),
      before: moment(before, DatetimeFormat).unix(),
      sort: JSON.stringify(sort),
      page: page + 1,
      k: filters.token,
    },
    {
      skipNull: true,
      skipEmptyString: true,
    },
  );

  if (params && params !== "") {
    params = "?" + params;
  }

  return API.get(`/moderator/${params}`, { responseType: "json" });
};

const getStats = (filters) => {
  const { token, after, before } = filters;

  if (!token || token === "") {
    return Promise.resolve({
    });
  }

  let params = queryString.stringify(
    {
      k: filters.token,
    },
    {
      skipNull: true,
      skipEmptyString: true,
    },
  );

  if (params && params !== "") {
    params = "?" + params;
  }

  return API.get(`/moderator/stats${params}`, { responseType: "json" });
};

const getRules = (filters) => {
  const { token } = filters;

  if (!token || token === "") {
    return Promise.resolve({
    });
  }

  let params = queryString.stringify(
    {
      k: filters.token,
    },
    {
      skipNull: true,
      skipEmptyString: true,
    },
  );

  if (params && params !== "") {
    params = "?" + params;
  }

  return API.get(`/moderator/v2/rules${params}`, { responseType: "json" });
};

const post = (setting, token) => {
  // return Promise.reject(new Error("Anti-PROD safe guard!"));
  if (!token || token === "") return Promise.reject(new Error("Please provide your authentication token"));
  return API.post(
    `/moderator/?k=${token}`,
    {
      ...setting,
      banned: setting.banned,
      allow_china: setting.allow_china,
      allow_russia: setting.allow_russia,
      allow_islamic: setting.allow_islamic,
    },
    //{ responseType: "json" },
    {
      // headers: {
      //     'Content-Type': 'application/json;charset=UTF-8',
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "GET, POST",
      //     "Access-Control-Allow-Headers": "X-Requested-With",
      //     responseType: "json"
      // }
    }
  );
};

export default {
  get,
  getStats,
  getRules,
  post,
};
