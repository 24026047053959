import React from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, TextField, Typography } from "@material-ui/core";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Checkbox } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import CachedIcon from "@material-ui/icons/Cached";
import { useDebouncedCallback } from "use-debounce";
import { StatusOption } from "../constants/status";
import { Countries } from "../constants/countries";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(1),
  },
  paper: {
    borderColor: "#c4c4c4",
  },
  accordion: {
    boxShadow: "none",
  },
  accordionSummary: {
    margin: 0,
    flexGrow: 0,
    "&$expanded": {
      minHeight: 56,
    },
  },
  typography: {
    color: "#757575",
  },
  button: {
    width: "100%",
    height: "100%",
  },
}));

const SearchFilters = (props) => {
  const classes = useStyles();

  const { filters, onFiltersUpdate, onReload } = props;

  const onAutocompleteChange = (stateKey) => (event, value) => onFiltersUpdate({ ...filters, [stateKey]: value });

  const debouncedTextFieldChange = useDebouncedCallback(
    (stateKey, value) =>
      onFiltersUpdate({
        ...filters,
        [stateKey]: value,
      }),
    25
  );

  const makeMultipleValuesAutocomplete = (label, freeSolo, options, stateKey) => (
    <Autocomplete
      size="small"
      freeSolo={freeSolo}
      multiple
      clearOnBlur
      clearOnEscape
      options={options}
      renderInput={(params) => <TextField label={label} variant="outlined" {...params} />}
      onChange={onAutocompleteChange(stateKey)}
    />
  );

  const makeBooleanAutocomplete = (label, stateKey) => (
    <Autocomplete size="small" options={Object.values(StatusOption)} renderInput={(params) => <TextField label={label} variant="outlined" {...params} />} onChange={onAutocompleteChange(stateKey)} />
  );

  const makeTextField = (label, fieldType, stateKey) => (
    <TextField size="small" fullWidth type={fieldType} defaultValue={filters[stateKey]} label={label} variant="outlined" onChange={(event) => debouncedTextFieldChange.callback(stateKey, event.target.value)} />
  );

  const secondRowFilters = [
    makeBooleanAutocomplete("Global", "banned"),
    makeBooleanAutocomplete("China", "allow_china"),
    makeBooleanAutocomplete("Russia", "allow_russia"),
    makeBooleanAutocomplete("Islamic Countries", "allow_islamic"),
  ];

  const thirdRowFilters = [
    makeTextField("Minimum Bid Price", "number", "min_bid_price"),
    makeTextField("Maximum Bid Price", "number", "max_bid_price"),
    makeTextField("Minimum Impressions", "number", "min_imps"),
    makeTextField("Maximum Impressions", "number", "max_imps"),
    makeTextField("Minimum Clicks", "number", "min_clicks"),
    makeTextField("Maximum Clicks", "number", "max_clicks"),
    makeTextField("Minimum Spent", "number", "min_spent"),
    makeTextField("Maximum Spent", "number", "max_spent"),
  ];

  const handleNotYetReviewedOnlyChange = (event) => {
    onFiltersUpdate({
      ...filters,
      'not_yet_reviewed_only': event.target.checked,
    })
  };

  const handleAutoModChange = (event) => {
    onFiltersUpdate({
      ...filters,
      'auto_moderated_ads': event.target.value,
    })
  };

  return (
    <>
      <Grid container className={classes.grid} spacing={3}>
        <Grid item xs={12} sm={12} md={2}>
          <div style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Typography style={{}}>KaiAds Moderation UI</Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <TextField value={filters['token']} size="small" fullWidth label="Authentication Token" variant="outlined" onChange={(event) => debouncedTextFieldChange.callback("token", event.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button fullWidth className={classes.button} color="primary" startIcon={<CachedIcon />} variant="contained" onClick={onReload(true)}>
            Reload
          </Button>
        </Grid>
      </Grid>
      
      <Grid container className={classes.grid} spacing={3}>
        <Grid item xs={12} lg={2} xl={2}>
          {makeMultipleValuesAutocomplete("Demand Partners", true, [], "partners")}
        </Grid>
        <Grid item xs={12} lg={3} xl={3}>
          {makeMultipleValuesAutocomplete("Countries", false, Countries, "countries")}
        </Grid>

        <Grid item xs={12} lg={2} xl={2}>
            {makeTextField("Source", "text", "source_including")}
        </Grid> 

        <Grid item xs={12} lg={3} xl={3}>
            {makeTextField("Text", "text", "text_including")}
        </Grid> 

        <Grid item xs={12} lg={2} xl={2}>
            <FormControl component="fieldset">
              <FormControlLabel
                control={<Checkbox />}
                label="Non-reviewed items only"
                labelPlacement="end"
                checked={filters.not_yet_reviewed_only} 
                onChange={handleNotYetReviewedOnlyChange}
                style={{minWidth: 300}}
              />
            </FormControl>
        </Grid> 
      </Grid>

      <Grid container className={classes.grid} spacing={3}>
        <Grid item xs={12} lg={8} xl={8}>
          <Paper className={classes.paper} variant="outlined">
            <Accordion className={classes.accordion}>
              <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.typography}>Metric Filters</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {secondRowFilters.map((component, i) => (
                    <Grid item xs={12} sm={6} md={3} key={`grid-r2-f${i}`}>
                      {component}
                    </Grid>
                  ))}
                  {thirdRowFilters.map((component, i) => (
                    <Grid item xs={12} sm={6} md={3} key={`grid-r3-f${i}`}>
                      {component}
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

          <Grid item xs={12} lg={2} xl={2}>
            <div style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                {makeTextField("After", "datetime-local", "after")}
            </div>
          </Grid>
          <Grid item xs={12} lg={2} xl={2}>
            <div style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
              {makeTextField("Before", "datetime-local", "before")}
            </div>
          </Grid>

        {/* <Grid item xs={12} lg={4} xl={4}>
          <div style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Auto-Moderated ads</FormLabel>
              <RadioGroup row aria-label="position" name="position" defaultValue="none" id="auto_moderated_ads">
                <FormControlLabel
                  value=""
                  control={<Radio color="primary" />}
                  label="None"
                  labelPlacement="end"
                  checked={filters.auto_moderated_ads === ''} 
                  onChange={handleAutoModChange}
                />
                <FormControlLabel
                  value="image"
                  control={<Radio color="primary" />}
                  label="Image"
                  labelPlacement="end"
                  checked={filters.auto_moderated_ads === 'image'} 
                  onChange={handleAutoModChange}
                />
                <FormControlLabel
                  value="text"
                  control={<Radio color="primary" />}
                  label="Text"
                  labelPlacement="end"
                  checked={filters.auto_moderated_ads === 'text'} 
                  onChange={handleAutoModChange}
                />
                <FormControlLabel
                  value="all"
                  control={<Radio color="primary" />}
                  label="Image+Text"
                  labelPlacement="end"
                  checked={filters.auto_moderated_ads === 'all'} 
                  onChange={handleAutoModChange}
                />
              </RadioGroup>
          </FormControl>
        </div>
        </Grid> */}
      </Grid>
    </>
  );
};

SearchFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
};

export default SearchFilters;
